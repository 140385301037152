import React from 'react';
import Card from './Card';
import { ProjectList } from '../typing/Project';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { breakpoints, gutter } from '../styles/variables';
import { replaceCategoryName } from '../utils/strings';

type Props = {
	project: ProjectList;
	image?: IGatsbyImageData;
	imageLoading?: 'eager' | 'lazy';
};

const ProjectCard: React.FC<Props> = ({ project: { title, slug, client, category, date }, image, imageLoading }) => {
	const Img = image ? (
		<GatsbyImage
			image={image}
			sizes={`(max-width: ${breakpoints.sm - 1}px) calc(100vw - (${gutter.full} * 2)), (max-width: ${breakpoints.md - 1}px) calc((100vw - (${gutter.full} * 3)) / 2), (max-width: ${breakpoints.lg - 1}px) calc((100vw - (${gutter.md.full} * 3)) / 2), (max-width: ${breakpoints.xxxl - 1}px) calc((100vw - (${gutter.md.full} * 4)) / 3), calc((99rem - (${gutter.md.full} * 2)) / 3)`}
			loading={imageLoading}
			alt={title}
		/>
	) : null;

	return (
		<Card
			title={title}
			to={slug}
			text={`${client} — ${date}`}
			label={replaceCategoryName(category)}
			img={Img}
		/>
	);
};

export default ProjectCard;
