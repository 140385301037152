import React from 'react';
import { fontSize, fontWeight, opacity, spacer, transition } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import Link from './Link';
import styled from '@emotion/styled';
import ArrowRight from '../img/arrow_right.module.svg';
import ArrowLeft from '../img/arrow_left.module.svg';

type ArrowDirection = 'left' | 'right';

type StyledLinkProps = {
	arrowDirection: ArrowDirection;
	isDisabled: boolean;
};

const StyledLink = styled(Link, {shouldForwardProp(propName: string): boolean { return !['arrowDirection', 'isDisabled'].includes(propName) }})`
	display: flex;
	align-items: center;
	font-weight: ${fontWeight.bold};
	font-size: ${fontSize.xs};
	cursor: ${({ isDisabled }: StyledLinkProps) => (isDisabled ? 'default' : 'poiner')};
	opacity: ${({ isDisabled }: StyledLinkProps) => (isDisabled ? opacity.disabled : '1')};
	pointer-events: ${({ isDisabled }: StyledLinkProps) => (isDisabled ? 'none' : 'auto')};

	&:hover {
		> svg {
			transform: translate(${({ arrowDirection, isDisabled }: StyledLinkProps) => (isDisabled ? '0' : `${arrowDirection === 'left' ? '-' : ''}${spacer['2']}`)}, 0);
		}
	}

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}

	> svg {
		margin-left: ${({ arrowDirection }: StyledLinkProps) => (arrowDirection === 'right' ? spacer['5'] : '0')};
		margin-right: ${({ arrowDirection }: StyledLinkProps) => (arrowDirection === 'left' ? spacer['5'] : '0')};
		transition: transform ${transition.hover.duration} ${transition.hover.timingFunction};
	}
`;

type Props = {
	to: string;
	hasLangPrefix?: boolean;
	title: string;
	isDisabled?: boolean;
	arrowDirection?: ArrowDirection;
};

const NavLink: React.FC<Props> = ({ to, title, isDisabled = false, arrowDirection = 'right', hasLangPrefix = true }) => {
	return (
		<StyledLink
			to={to}
			arrowDirection={arrowDirection}
			isDisabled={isDisabled}
			hasLangPrefix={hasLangPrefix}
		>
			{arrowDirection === 'left' && <ArrowLeft />}
			{title}
			{arrowDirection === 'right' && <ArrowRight />}
		</StyledLink>
	);
};

export default NavLink;
