import React from 'react';
import { PageCategoryStructure } from '../typing/Page';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';
import { css } from '@emotion/core';
import { border, color, fontSize, fontWeight, gutter, spacer, transition } from '../styles/variables';
import Link from './Link';
import ArrowRight from '../img/arrow_right.module.svg';
import { getRemSize, multiLine } from '../styles/mixins';

type StyledProps = {
	isActive?: boolean;
};

const StyledNavList = styled.ul`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	padding: 0;
	border-top: ${border.full};
	border-bottom: ${border.full};
	list-style: none;

	${breakpointUp('md')} {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: ${gutter.md.full};
	}
`;

const StyledNavListItem = styled.li`
	&:not(:last-child) {
		border-bottom: ${border.full};

		${breakpointUp('md')} {
			border-bottom: 0;
		}
	}

	${breakpointUp('md')} {
		margin-top: 0;
	}
`;

const StyledIndex = styled.span`
	display: none;
	font-size: ${fontSize.xs};
	margin-bottom: ${spacer['2']};
	transition: transform ${transition.hover.duration} ${transition.hover.timingFunction}, color ${transition.hover.duration} ${transition.hover.timingFunction};

	${breakpointUp('md')} {
		display: inline-block;
	}
`;

const StyledTitle = styled.span`
	display: block;
	margin-bottom: ${spacer['4']};
	line-height: 1.2;
	font-weight: ${fontWeight.bold};
	transition: transform ${transition.hover.duration} ${transition.hover.timingFunction}, color ${transition.hover.duration} ${transition.hover.timingFunction};
	${multiLine(2)};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['6']};
		font-size: ${fontSize.md};
	}
`;

const StyledLabel = styled.span`
	display: flex;
	align-items: center;
	font-weight: ${fontWeight.bold};
	font-size: ${fontSize.xs};
	transition: transform ${transition.hover.duration} ${transition.hover.timingFunction}, color ${transition.hover.duration} ${transition.hover.timingFunction};
	visibility: ${({ isActive }: StyledProps) => (isActive ? 'hidden' : 'visible')};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}

	> svg {
		margin-left: ${spacer['5']};
		transition: transform ${transition.hover.duration} ${transition.hover.timingFunction};

		path {
			transition: stroke ${transition.hover.duration} ${transition.hover.timingFunction};
		}
	}
`;

const linkCss = (isActive?: boolean) => css`
	display: block;
	position: relative;
	padding: ${spacer['6']} 0;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: ${color.inverse.bg};
		transform: scaleX(0);
		transform-origin: left;
		transition: transform ${transition.hover.duration} ${transition.hover.timingFunction};
	}

	&::after {
		content: '';
		display: ${isActive ? 'block' : 'none'};
		position: absolute;
		width: ${getRemSize(18)};
		height: ${getRemSize(18)};
		left: 0;
		bottom: ${spacer['6']};
		border-radius: ${getRemSize(9)};
		background-color: ${color.text};

		${breakpointUp('md')} {
			width: ${getRemSize(20)};
			height: ${getRemSize(20)};
			border-radius: ${getRemSize(10)};
		}
	}

	${!isActive &&
	`		
		&:hover,
		&:focus {
			${breakpointUp('lg')} {
				color: ${color.inverse.linkHover};
				
				&::before {
					transform: scaleX(1);
				}
							
				> span {
					transform: translate(${spacer['6']}, 0);
					
					> svg {
						transform: translate(${spacer['2']}, 0);
					
						path {
							stroke: ${color.inverse.linkHover};					
						}
					}
				}
			}
		}
	`}
`;

type Props = {
	categories: PageCategoryStructure[];
	label: string;
	activeCategory?: string;
	onActivate?: (category: PageCategoryStructure) => void;
	className?: string;
};

const CategoriesNav: React.FC<Props> = ({ categories, onActivate, activeCategory, label, className }) => {
	return (
		<StyledNavList className={className}>
			{categories.map((category, i) => {
				const isActive = category.name === activeCategory;
				return (
					<StyledNavListItem key={category.name}>
						<Link
							to={category.slug}
							css={linkCss(isActive)}
							onMouseEnter={() => (onActivate ? onActivate(category) : null)}
						>
							<StyledIndex>0{i + 1}</StyledIndex>
							<StyledTitle dangerouslySetInnerHTML={{ __html: category.title.replace('|', '<br>') }} />
							<StyledLabel isActive={isActive}>
								{label}
								<ArrowRight />
							</StyledLabel>
						</Link>
					</StyledNavListItem>
				);
			})}
		</StyledNavList>
	);
};

export default CategoriesNav;
