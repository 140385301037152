import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';
import { border, card, color, fontSize, fontWeight, spacer, transition } from '../styles/variables';
import Link from './Link';
import { singleLine } from '../styles/mixins';

const StyledArticle = styled.article`
	width: 100%;
	overflow: hidden;
`;

const StyledImgContainerOuter = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: ${card.imgRatio};
	margin-bottom: ${spacer['2']};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['3']};
	}
`;

const StyledImgContainerInner = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	background-color: ${color.gray['16']};

	> img {
		width: 100%;
		height: auto;
	}
`;

const StyledLink = styled(Link)`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: ${spacer['2']};

	${breakpointUp('md')} {
		padding-bottom: ${spacer['3']};

		&:hover {
			&::after {
				transform: scaleX(1);
			}
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			border-bottom: ${border.full};
			transform: scaleX(0);
			transform-origin: left;
			transition: transform ${transition.hover.duration} ${transition.hover.timingFunction};
		}
	}
`;

const StyledLabel = styled.span`
	margin-bottom: ${spacer['2']};
	font-size: ${fontSize.xs};
	font-weight: ${fontWeight.bold};
	${singleLine()};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['3']};
	}
`;

const StyledTitle = styled.h2`
	margin-bottom: ${spacer['1']};
	font-size: ${fontSize.md};
	font-weight: ${fontWeight.bold};
	${singleLine()};

	${breakpointUp('md')} {
		font-size: ${fontSize.lg};
	}
`;

const StyledText = styled.p`
	margin-bottom: 0;
	color: ${color.textSemimuted};
	font-size: ${fontSize.xs};
	${singleLine()};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}
`;

type Props = {
	title: string;
	img?: ReactNode;
	label?: string;
	text?: string;
	to: string;
};

const Card: React.FC<Props> = ({ title, label, text, to, img }) => {
	return (
		<StyledArticle>
			<StyledLink to={to}>
				<StyledImgContainerOuter>
					<StyledImgContainerInner>{img ? img : null}</StyledImgContainerInner>
				</StyledImgContainerOuter>
				{label && <StyledLabel>{label}</StyledLabel>}
				<StyledTitle>{title}</StyledTitle>
				{text && <StyledText>{text}</StyledText>}
			</StyledLink>
		</StyledArticle>
	);
};

export default Card;
