import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';
import { breakpoints, color, fontSize, gutter, spacer } from '../styles/variables';

type rCols = {
	[key in keyof Partial<typeof breakpoints>]: number;
};

type StyledGridProps = {
	cols: number;
	rCols?: rCols;
};

const StyledGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(${({ cols }: StyledGridProps) => cols}, 1fr);
	grid-gap: ${gutter.full};

	${breakpointUp('md')} {
		grid-gap: ${gutter.md.full};
	}

	${({ rCols }: StyledGridProps) => `
		${
	rCols &&
			Object.keys(rCols)
				.map(
					breakpoint => `
			${breakpointUp(breakpoint as keyof typeof breakpoints)} {
				grid-template-columns: repeat(${rCols[breakpoint as keyof typeof breakpoints]}, 1fr);
			}
		`
				)
				.join('')
}
	`}
`;

const StyledTitle = styled.h2`
	font-size: ${fontSize.xs};
	margin-bottom: ${spacer['6']};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
		margin-bottom: ${spacer['8']};
	}
`;

const StyledMessage = styled.p`
	font-size: ${fontSize.md};
	color: ${color.textMuted};

	${breakpointUp('md')} {
		font-size: ${fontSize.lg};
	}
`;

type Props = {
	children: ReactNode;
	cols: number;
	rCols?: rCols;
	title?: string;
	noItemsMessage?: string;
};

const ListGrid: React.FC<Props> = ({ children, cols, rCols, title, noItemsMessage, ...props }) => {
	const hasMessage = (children === null || (Array.isArray(children) && !children.length)) && noItemsMessage !== null;

	return (
		<>
			{title && <StyledTitle>{title}</StyledTitle>}
			<StyledGrid
				cols={cols}
				rCols={rCols}
				{...props}
			>
				{children}
			</StyledGrid>
			{hasMessage && <StyledMessage>{noItemsMessage}</StyledMessage>}
		</>
	);
};

export default ListGrid;
